<template>
  <!-- 报工总览 -->
  <div>
    <div class="jobBooking_main">
      <van-nav-bar left-text="报工总览"
                   left-arrow
                   @click-left="onClickLeft"
                   :fixed="true">
        <template #right
                  v-if="isToday">
          <van-button round
                      type="info"
                      size="small"
                      :icon="require('@assets/img/job_booking02.png')"
                      @click="autoReport">自动掉落件报工</van-button>
        </template>
      </van-nav-bar>
      <div class="content">
        <!-- 今日报工 -->
        <div v-if="isToday"
             class="today_content">
          <div class="date"
               @click="checkExDate('today')">
            <i class="fa fa-calendar"></i>
            {{ bd }}
            <span style="margin:0 5px;">至</span>
            {{ ed }}
            <div class="faRight">
              <i class="fa fa-sort-desc"></i>
            </div>
          </div>
          <div class="work_list">
            <div class="work_box"
                 v-for="(to,idx) in todayList"
                 :key="idx+'to'">
              <div class="a_row">
                <div class="text_left">单号</div>
                <div class="text_right"
                     style="font-size:14px;">{{to.listno}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">日期</div>
                <div class="text_right">{{to.yymmdd}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件编号</div>
                <div class="text_right">{{to.partcode}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件名称</div>
                <div class="text_right">{{to.partname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工序</div>
                <div class="text_right">{{to.processname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">机台/产线</div>
                <div class="text_right">{{to.machinename}}</div>
              </div>
              <div class="b_row">
                <div class="c_row">
                  <div class="text_left">派工数量</div>
                  <div class="text_right">{{to.pgnum}}</div>
                </div>
                <div class="c_row">
                  <div class="text_left">剩余数量</div>
                  <div class="text_right">{{to.wscnum}}</div>
                </div>
              </div>
              <van-divider />
              <div class="baogong">
                <van-field v-model="to.bgnum"
                           clearable
                           label="报工数量："
                           left-icon="edit"
                           placeholder="报工数量" />
                <van-button type="info"
                            size="small"
                            @click="subimtToday(to)">提交</van-button>
              </div>
            </div>
            <van-empty v-if="todayList.length==0"
                       image="search"
                       description="暂无数据" />
          </div>
        </div>
        <!-- 历史报工 -->
        <div v-else
             class="his_content">
          <div class="date"
               @click="checkExDate('today')">
            <i class="fa fa-calendar"></i>
            {{ bd }}
            <span style="margin:0 5px;">至</span>
            {{ ed }}
            <div class="faRight">
              <i class="fa fa-sort-desc"></i>
            </div>
          </div>
          <div class="work_list">
            <div class="work_box"
                 v-for="(item,idx) in hisEmpBgList"
                 :key="'his'+idx">
              <img class="examined_img"
                   :src="require(item.ischeck?'@/assets/img/job_booking08.png':'@/assets/img/job_booking07.png')"
                   alt />
              <div class="examined">{{`${item.ischeck?'已审核':'待审核'}`}}</div>
              <div class="a_row"
                   style="margin-top: 15px;">
                <div class="text_left">单号</div>
                <div class="text_right"
                     style="font-size:14px;">{{item.listno}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">日期</div>
                <div class="text_right">{{item.yymmdd}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件编号</div>
                <div class="text_right">{{item.partcode}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工件名称</div>
                <div class="text_right">{{item.partname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">工序</div>
                <div class="text_right">{{item.processname}}</div>
              </div>
              <div class="a_row">
                <div class="text_left">机台/产线</div>
                <div class="text_right">{{item.machinename}}</div>
              </div>
              <div class="b_row">
                <div class="c_row">
                  <div class="text_left">派工数量</div>
                  <div class="text_right">{{item.pgnum}}</div>
                </div>
                <div class="c_row">
                  <div class="text_left">剩余数量</div>
                  <div class="text_right">{{item.wscnum}}</div>
                </div>
              </div>
              <van-divider />
              <div class="baogong">
                <van-field v-model="item.bgnum"
                           clearable
                           label="已报工数量："
                           readonly />
                <van-button v-if="!item.ischeck"
                            type="info"
                            size="small"
                            @click="updateHisNum(item)">修改报工数量</van-button>
              </div>
            </div>
            <van-empty v-if="hisEmpBgList.length==0"
                       image="search"
                       description="暂无数据" />
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="f_btn"
             @click="checkFoot('to')">
          <img :src="require(isToday?'@/assets/img/job_booking03.png':'@/assets/img/job_booking04.png')"
               alt />
          <div>当日报工</div>
        </div>
        <div class="f_btn"
             @click="checkFoot()">
          <img :src="require(isToday?'@/assets/img/job_booking06.png':'@/assets/img/job_booking05.png')"
               alt />
          <div>报工历史</div>
        </div>
      </div>
    </div>
    <!-- 自动掉落件报工弹窗 -->
    <van-dialog v-model="showAuto"
                class="Automatic_report"
                show-cancel-button
                confirm-button-text='提交审核'
                confirm-button-color='#0782F5'
                :beforeClose="submitAutoReport">
      <template #title>
        <div class="title">自动掉落件报工</div>
        <div class="desc">填写当日自动掉落件报工</div>
      </template>
      <div class="content">
        <van-form label-align="right">
          <van-field readonly
                     clickable
                     name="calendar"
                     right-icon="arrow-down"
                     v-model="autoForm.yymmdd"
                     label="日期"
                     placeholder="选择日期"
                     @click="checkExDate('form')" />
          <!-- <van-field readonly
                     clickable
                     label="工件"
                     right-icon="arrow-down"
                     v-model="autoForm.partname"
                     placeholder="选择工件"
                     @click="showGjPicker = true" /> -->
          <van-field readonly
                     clickable
                     label="工序"
                     right-icon="arrow-down"
                     v-model="autoForm.processname"
                     placeholder="选择工序"
                     @click="showGxPicker = true" />
          <van-field readonly
                     clickable
                     label="线长"
                     right-icon="arrow-down"
                     v-model="autoForm.empname"
                     placeholder="选择线长"
                     @click="showEmpTreePop " />
          <van-field v-model="autoForm.bgnum"
                     label="报工数量"
                     placeholder="报工数量" />
        </van-form>

      </div>
    </van-dialog>
    <!-- 日期日历弹窗 -->
    <van-calendar v-model="showCalendar"
                  :type="whichDate == 'today'?'range':'single'"
                  :allow-same-day="whichDate == 'today'?true:false "
                  :min-date="minDate"
                  :max-date="maxDate"
                  @confirm="onConfirmCal" />
    <!-- 工件下拉弹窗 -->
    <van-popup v-model="showGjPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="gjcolumns"
                  value-key="partname"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showGjPicker = false"
                  @confirm="onConfirmGj" />
    </van-popup>
    <!-- 工序下拉弹窗 -->
    <van-popup v-model="showGxPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  :columns="gxcolumns"
                  value-key="processname"
                  :confirm-button-text="$t('module.confirm')"
                  :cancel-button-text="$t('module.cancel')"
                  @cancel="showGxPicker = false"
                  @confirm="onConfirmGx">
        <template #title>
          <van-search shape="round"
                      v-model="searchGX"
                      placeholder="搜索关键词"
                      @input="searchGXList" />
        </template>
      </van-picker>
    </van-popup>

    <!-- 修改报工数量弹窗 -->
    <van-dialog v-model="showUpdateNum"
                class="Automatic_report"
                show-cancel-button
                :confirm-button-text="$t('module.confirm')"
                confirm-button-color='#0782F5'
                :beforeClose="updateHisReport">
      <template #title>
        <div class="title">修改报工数量</div>
        <div class="desc">未审核前，可修改报工数量</div>
      </template>
      <div class="content">
        <van-form label-align="right">
          <van-field readonly
                     name="calendar"
                     v-model="updateForm.yymmdd"
                     label="日期" />
          <van-field readonly
                     label="工序"
                     v-model="updateForm.processname" />
          <van-field readonly
                     label="机台/产线"
                     v-model="updateForm.machinename" />
          <van-field v-model="updateForm.bgnum"
                     label="报工数量"
                     placeholder="报工数量" />
        </van-form>
      </div>
    </van-dialog>

    <!-- 提示信息弹窗 -->
    <van-dialog v-model="showHintDia"
                class="Automatic_report hint_dialog"
                :show-confirm-button="false">
      <template #title>
        <div style="height:60px"></div>
      </template>
      <div class="content">
        <img :src="require(isSuccess?'@/assets/img/job_booking11.png':'@/assets/img/job_booking10.png')"
             alt />
        <div class="hint_msg">{{hintMessage}}</div>
        <van-button type="info"
                    size="small"
                    round
                    @click="showHintDia = false">好的</van-button>
      </div>
    </van-dialog>

    <!-- 选择人员树弹窗 -->
    <PopTree ref="popTreeNode"
             :whichTree="'employee'"
             module="814"
             :multiseltree="false"
             @treeSelectValEvent="treeSelectValEvent"></PopTree>
  </div>
</template>

<script>
import PopTree from "@components/PopTree/index.vue";
import { getCurDayBgData, postOneToolsNum, getBggjList, getBggxList, getHisEmpBgList, postEmpDljBg, saveHisXgBg } from '@api/wxjj.js'
export default {
  components: {
    PopTree
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      isToday: true,
      minDate: new Date(new Date().getFullYear() - 20, 0, 1),
      maxDate: new Date(new Date().getFullYear() + 20, 0, 31),
      today: null,
      bd: this.formatDate(new Date(), 'yyyy-MM-dd'),
      ed: this.formatDate(new Date(), 'yyyy-MM-dd'),
      todayList: [],
      showAuto: false,
      showCalendar: false,
      whichDate: 'today', // 日历选的是哪个日期，默认选左上角条件日期
      autoForm: {
        yymmdd: this.formatDate(new Date(), 'yyyy-MM-dd'),
        partid: '',
        partname: '',
        partcode: '',
        processid: '',
        processcode: '',
        processname: '',
        empid: '',
        empname: '',
        bgnum: ''
      },
      showCalPicker: false,
      showGjPicker: false,
      gjcolumns: [],
      showGxPicker: false,
      gxcolumns: [],
      showUpdateNum: false,
      hisEmpBgList: [], // 历史报工数据
      updateForm: {
        yymmdd: '',
        machinename: '',
        processname: '',
        bgnum: '',
        autoid: 0
      },
      showHintDia: false,
      isSuccess: true,
      hintMessage: '',
      searchGX: '', // 工序下拉搜索值

    }
  },
  watch: {
    showGxPicker (n) {
      if (n)      {
        this.getBggxListData();
      } else      {
        this.searchGX = ''
      }
    }
  },
  created () {
    if (this.isToday)    {
      this.getCurDayBgDataInfo()
    } else    {
      this.getHisEmpBgListData()
    }
  },
  methods: {
    // 工序下拉搜索
    searchGXList () {
      if (this.searchGX)      {
        this.gxcolumns = this.gxcolumns.filter(it => { return it.processname.indexOf(this.searchGX) >= 0 || it.processcode.indexOf(this.searchGX) >= 0 })
      } else      {
        this.getBggxListData();
      }
    },
    // 调起
    showEmpTreePop () {
      this.$refs.popTreeNode.module = "814";
      this.$refs.popTreeNode.treename = "glsxztree";
      this.$refs.popTreeNode.editstate = 2;
      this.$refs.popTreeNode.showEmpTreePop('选择线长');
    },
    // 回调
    treeSelectValEvent (data) {
      this.autoForm.empname = data.empname;
      this.autoForm.empid = data.empid;
    },
    // 回退
    onClickLeft () {
      this.$router.push('/home')
    },
    // 提交报工数量
    subimtToday (to) {
      if (to.bgnum)      {
        postOneToolsNum({
          autoid: to.autoid,
          bgnum: to.bgnum,
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '报工数量修改成功'
            this.getCurDayBgDataInfo()
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
          }
        })
      } else      {
        this.showHintDia = true
        this.isSuccess = false
        this.hintMessage = '报工数量不能为空！'
      }

    },
    // 自动掉落工件提交
    submitAutoReport (action, done) {
      if (action == 'confirm')      {
        postEmpDljBg({
          yymmdd: this.autoForm.yymmdd,
          partid: this.autoForm.partid,
          processid: this.autoForm.processid,
          empid: this.autoForm.empid,
          bgnum: this.autoForm.bgnum,
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '自动掉落件报工成功！'
            // 刷新数据
            this.getCurDayBgDataInfo()
            done();
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false)
          }
        })
      } else      {
        done();
      }
    },
    // 工件选择回调
    onConfirmGj (value) {
      this.autoForm.partcode = value.partcode
      this.autoForm.partname = value.partname
      this.autoForm.partid = value.partid
      this.showGjPicker = false
    },
    // 工序选择回调
    onConfirmGx (value) {
      this.autoForm.processcode = value.processcode
      this.autoForm.processname = value.processname
      this.autoForm.processid = value.processid
      this.showGxPicker = false
    },
    // 调起日历弹窗方法
    checkExDate (val) {
      this.showCalendar = true;
      this.whichDate = val
    },
    // 日期选择回调
    onConfirmCal (date) {
      if (this.whichDate == 'today')      {
        // this.today = this.formatDate(date, 'yyyy-MM-dd')
        this.bd = this.formatDate(date[0], 'yyyy-MM-dd')
        this.ed = this.formatDate(date[1], 'yyyy-MM-dd')
        if (this.isToday)        {
          this.getCurDayBgDataInfo()
        } else        {
          this.getHisEmpBgListData()
        }
      } else      {
        this.autoForm.yymmdd = this.formatDate(date, 'yyyy-MM-dd')
        this.getBggjListData()
        this.getBggxListData()
      }
      this.showCalendar = false;
    },
    // 工件下拉项目
    getBggjListData () {
      getBggjList({
        username: this.userInfo.username,
        yymmdd: this.autoForm.yymmdd
      }).then(res => {
        this.gjcolumns = res.data
      })
    },
    // 工序下拉项目
    getBggxListData () {
      getBggxList({
        username: this.userInfo.username,
        yymmdd: this.autoForm.yymmdd
      }).then(res => {
        this.gxcolumns = res.data
      })
    },
    // 自动掉落件报工
    autoReport () {
      this.getBggjListData()
      this.getBggxListData()
      this.showAuto = true
    },
    // 获取当日报工数据
    getCurDayBgDataInfo () {
      getCurDayBgData({
        username: this.userInfo.username,
        bd: this.bd,
        ed: this.ed
      }).then(res => {
        this.todayList = res.data
      })
    },
    // 获取历史报工数据
    getHisEmpBgListData () {
      getHisEmpBgList({
        username: this.userInfo.username,
        bd: this.bd,
        ed: this.ed
        // yymmdd: this.today
      }).then(res => {
        this.hisEmpBgList = res.data
      })
    },
    // 修改历史报工数量按钮
    updateHisNum (it) {
      this.showUpdateNum = true
      this.updateForm = { ...this.updateForm, ...it }
    },
    // 修改历史数据弹窗回调
    updateHisReport (action, done) {
      if (action == 'confirm')      {
        saveHisXgBg({
          autoid: this.updateForm.autoid,
          bgnum: this.updateForm.bgnum,
          username: this.userInfo.username
        }).then(res => {
          this.showHintDia = true
          if (res.data[0].info == '')          {
            this.isSuccess = true
            this.hintMessage = '修改报工提交成功！'
            // 刷新数据
            this.getHisEmpBgListData()
            done();
          } else          {
            this.isSuccess = false
            this.hintMessage = res.data[0].info
            done(false)
          }
        })
      } else      {
        done();
      }
    },
    // 底部按钮切换
    checkFoot (val) {
      if (val == 'to')      { // 今日
        this.isToday = true
        this.getCurDayBgDataInfo()
      } else      { // 历史
        this.getHisEmpBgListData()
        this.isToday = false
      }
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? '0' + m : m
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)      {
        const time = new Date(date)
        const y = time.getFullYear()
        const m = time.getMonth() + 1
        const d = time.getDate()
        const h = time.getHours()
        const mm = time.getMinutes()
        const s = time.getSeconds()
        if (rule == 'yyyy-MM-dd')        {
          return y + '-' + this.add0(m) + '-' + this.add0(d)
        } else        {
          return (
            y +
            '-' +
            this.add0(m) +
            '-' +
            this.add0(d) +
            ' ' +
            this.add0(h) +
            ':' +
            this.add0(mm)
          )
          // + ':' + this.add0(s)
        }
      } else      {
        return ''
      }
    },

  }
}
</script>

<style scoped lang="less">
.hint_dialog {
  .content {
    text-align: center;
    img {
      width: 160px;
      height: 160px;
    }
    .hint_msg {
      margin-top: 20px;
      font-size: 32px;
      font-weight: bold;
    }
    .van-button {
      margin: 40px 0;
      width: 240px;
      font-size: 28px;
    }
  }
}
.Automatic_report {
  /deep/.van-dialog__header {
    padding: 20px 0;
    background-image: url("~@/assets/img/job_booking09.png");
    background-repeat: no-repeat;
    background-size: 100%;
    color: #08306b;
    .title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .desc {
      font-size: 28px;
    }
  }
}
.jobBooking_main {
  height: 100vh;
  background-image: url("~@/assets/img/job_booking01.png");
  overflow: hidden;
  background-color: #eaedef;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 1.22667rem;
  .van-nav-bar {
    height: 92px;
    background: #bcdfff;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #333;
        font-size: 0.5rem;
      }
      .van-nav-bar__text {
        color: #333;
        font-size: 36px;
        font-weight: bold;
      }
    }
    /deep/.van-nav-bar__title {
      color: #333;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__right {
      .mult_switch {
        display: flex;
        align-items: center;
        .text {
          margin-left: 5px;
          color: #ffffff;
        }
      }
    }
  }
  .van-hairline--bottom::after {
    border: unset;
  }
  .content {
    height: calc(100% - 212px);
    margin-top: 92px;
    overflow-y: auto;
    .today_content,
    .his_content {
      .date {
        font-size: 28px;
        padding: 20px;
        display: flex;
        align-items: center;
        .fa-calendar {
          margin-right: 6px;
        }
        .faRight {
          i {
            margin-left: 6px;
            margin-bottom: 16px;
          }
        }
      }
      .work_list {
        .work_box {
          font-size: 28px;
          background: #fff;
          box-shadow: 8px 6px 30px 0px rgba(175, 175, 175, 0.21);
          border-radius: 10px;
          margin: 20px;
          padding: 20px;
          border-left: 8px solid #93a6bd;
          .text_left {
            color: #ababab;
            width: 25%;
            text-align: right;
            padding-right: 20px;
          }
          .text_right {
          }
          .a_row {
            display: flex;
            margin-bottom: 20px;
          }
          .b_row {
            display: flex;
            .c_row {
              flex: 1;
              display: flex;
              .text_left {
                width: 50%;
                text-align: right;
                padding-right: 20px;
              }
            }
          }
          .van-divider {
            margin: 20px 0;
          }
          .baogong {
            display: flex;
            align-items: center;
            /deep/.van-field {
              padding: 0;
              flex: 1;
              .van-field__left-icon {
                color: #007bee;
              }
              .van-field__label {
                color: #007bee;
              }
            }
            .van-cell::after {
              border-bottom: 0;
            }
            .van-button {
              width: 150px;
            }
          }
        }
      }
    }
    .his_content {
      .work_list {
        .work_box {
          position: relative;
          border-left: 0;
          .examined_img {
            position: absolute;
            top: 0px;
            left: 0;
            width: 150px;
          }
          .examined {
            position: absolute;
            left: 30px;
            top: 0px;
          }
          .baogong {
            /deep/.van-field {
              .van-field__label {
                margin-right: 0;
              }
            }
            .van-button {
              width: 200px;
            }
          }
        }
      }
    }
  }
  .footer {
    height: 120px;
    background: #fff;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 28px;
    display: flex;
    .f_btn {
      flex: 1;
      text-align: center;
      padding-top: 10px;
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
</style>